:root {
  --header-background: #000;
  --header-height: 60px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

th {
  word-break: keep-all;
  white-space: nowrap;
}
.profilePage {
  padding: 20px;
  box-shadow: 0px 0px 5px #000;
  border-radius: 10px;
  margin: 24px;
  outline: 1px solid #000;
}
.profilePage input {
  padding: 10px;
}
.profilePage label {
  font-weight: bold;
  color: #000;
}
.upload-text {
  background: rgb(100, 108, 110);
  border-radius: 12px;
  border: 0px;
  outline: none;
  font-size: 14px;
  color: #fff;
  padding: 7px 15px;
  width: 150px;
  display: inline-block;
}

.browse-text {
  font-size: 18px;
  color: #5e5e5e;
  font-weight: 300;
  margin: 15px 0px;
}

.form-check-input:focus {
  box-shadow: none !important;
}

.admin_avatar_img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}

.admin_icons svg {
  vertical-align: text-top;
  margin-right: 4px;
}

.admin_avatar_add_btn {
  background-color: rgb(100, 108, 110) !important;
  padding: 10px 35px !important;
  border-radius: 12px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  border: none !important;
}

.admin_login_button:hover {
  color: #fff;
}

.admin_login_button {
  background-color: rgb(100, 108, 110) !important;
  padding: 12px 35px !important;
  border-radius: 12px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.admin_login_page {
  border: 2px solid rgb(100, 108, 110);
  border-radius: 20px;
  padding: 10px 30px 30px 30px;
}

.notification-title {
  color: #333333;
  display: block;
  float: left;
  font-size: 14px;
}

.clear-noti {
  color: #f83f37;
  float: right;
  font-size: 11px;
  text-transform: uppercase;
}

.notification-list li {
  margin-top: 0;
  border-bottom: 1px solid #f5f5f5;
}

.drop_menu_notification {
  position: absolute;
  margin-top: 18px;
  transform: translate(-174px, 12px);
  height: 40vh;
  overflow: hidden;
  overflow-y: auto;
}

.notification-list {
  padding: 0px;
  list-style: none;
}

.noti-details {
  color: #989c9e;
  margin: 0;
  text-decoration: none;
}

.noti-title {
  color: #333333;
  text-decoration: none;
}

.noti-time {
  margin: 0;
}

.topnav-dropdown-header {
  border-bottom: 1px solid #eeeeee;
  font-size: 12px;
  height: 32px;
  line-height: 32px;
  padding: 0 12px;
}

.avatar {
  background-color: transparent;
  color: #ffffff;
  display: inline-block;
  font-weight: 500;
  height: 34px;
  line-height: 38px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  width: 34px;
  position: relative;
  white-space: nowrap;
  margin: 0 10px 0 0;
  border-radius: 50%;
}

.avatar > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  margin-top: 5px;
}

.badge {
  color: #ffffff;
  font-weight: 700;
  background-color: #7460ee;
  position: absolute;
  left: 17px;
  bottom: 5px;
}

.Avatar20 {
  margin-right: 5px;
  border-radius: 100%;
  width: 28px;
  height: 28px;
  position: relative;
}

.status {
  position: absolute;
  border: 2px solid #ffffff;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: rgb(113, 179, 14);
  display: inline-block;
  margin-top: 18px;
  right: 62px;
}

.notification-time {
  font-size: 12px;
  line-height: 1.35;
  color: #bdbdbd;
}

.header_search_icon {
  font-size: 24px;
  position: absolute;
  top: 8px !important;
  right: 15px;
}

.header_search_input {
  margin-right: 30px;
  width: 350px;
  position: relative;
}

.header_search_form_input {
  margin-top: 2px;
  border-radius: 50px !important;
  border: none !important;
}

.submenu_li {
  margin-bottom: 10px;
}
.addavtar_img img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
}
.addsubtopic_img img {
  width: 220px;
  height: 100px;
  border-radius: 5px;
  object-fit: cover
}
.loadercenter{position: relative;}
.addavtarmodal_img img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
}
/* .header_sidebar_dropdown:hover{
  border: none;
  } */

/* .header_sidebar_dropdown:focus{
  border: none;
} */

/* .header_sidebar_dropdown {
  border: none;
} */

/* .sub_test:hover {
  color: #fff !important;
}

.sub_test.active{
  background-color: #0d99ff;
  color: #fff;
  padding: 10px 20px 10px 5px;
  border-radius: 4px;
  margin: 0px;
  }

.sub_test {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  padding: 0;
  color: #fff;
  text-decoration: none;
}

.sidebar_sub_menu {
  margin: 0;
  padding: 6px 25px;
}

.item_icon {
  margin-left: 15px;
} */

.sidebar_category:hover {
  color: #ffffff !important;
}

.sidebar_category {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
}

.sidebar_head {
  font-size: 36px;
  font-weight: 600;
  line-height: 24px;
  color: #fff;
  margin-left: 25px;
  margin-top: 15px;
  margin-bottom: 40px;
}

.header {
  display: flex;
  z-index: 99;
  align-items: center;
  position: fixed;
  top: 0;
  background: var(--header-background);
  /* box-shadow: 0 1px 1px 0 rgba(137, 136, 136, 0.2); */
  border-bottom: 1px solid #e2e3e4;
 left:0px;
  height: 60px;
  right:0px;
}

.header .header-left {
  height: 60px;
  float: left;
  position: relative;
  text-align: center;
  padding-left: 15px;
}
.header .header-left .logo {
  display: inline-block;
  line-height: 60px;
}
a {
  text-decoration: none;
  outline: none;
  cursor: pointer;
}
.header .header-left .logo img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  padding-bottom: 2px;
  transition: all 0.3s;
}
.bars {
  display: flex;
  align-items: center;
  font-size: 22px;
  cursor: pointer;
  color: #fff;
}

.header .page-title-box {
  float: left;
  justify-content: center;
  height: 60px;
  padding: 15px 0px;
  border-radius: 0;
}
.header .page-title-box h3 {
  color: #0d99ff;
  font-size: 20px;
  font-weight: normal;
}
.right_header {
  display: flex;
  margin-right: 30px;
  position: relative;
  align-items: center;
}

.sidebar_img_zonkie {
  width: 69px;
  height: 26px;
  margin-top: 17px;
  margin-left: 24px;
  margin-bottom: 130.5px;
}

.calculator_sidebar_icon {
  width: 15px;
  height: 15px;
  margin-bottom: 25px;
  fill: #4169e1;
  color: #0d99ff;
}

.sidebar {
  flex-shrink: 0;
  overflow: hidden;
  z-index: 99;
  width: 256px;
  height: 1098px;
  align-items: center;
  justify-content: stretch;
  position: fixed;
  left: 0;
  top: var(--header-height);
  background: #000;
  transition: all 0.3s;
  line-height: unset;
  /* box-shadow: 0px 0px 10px #ccc; */
  margin-top: -60px;
}

.slider_admin {
  height: 595px;
  overflow-y: scroll;
}
.slider_admin::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.slider_admin::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
.slider_admin::-webkit-scrollbar-thumb {
  background: #000;
}

/* Handle on hover */
.slider_admin::-webkit-scrollbar-thumb:hover {
  background: #000;
}

.contain {
  width: auto;
  margin-top: 60px;
  background: #fff;
  transition: all 0.3s;
  margin-left: 256px;
}

.sidebar_img_icon {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.sidebar_close_button {
  margin-right: 8px;
  font-size: 28px;
  font-weight: 600;
  margin-top: 16px;
  display: none;
  color: #fff;
}

.link.active {
  color: #fff;
  background-color: #0d99ff;
  padding: 10px 20px 10px 16px;
  border-radius: 4px;
  margin: 0px 10px;
}

.link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* transition: all 0.5s ease; */
  margin-left: 24px;
  margin-right: 20px;
  margin-bottom: 10px;
  text-decoration: none;
}
/* .sidebar .link:hover {
  background: #809cac;

} */

.sidebar .link.active .sidebar_category {
  color: #fff;
}

.link_text {
  display: flex;
  gap: 20px;
  font-size: 20px;
  align-items: center;
  color: #333333;
  transition: all 0.5s ease;
  width: 100%;
  font-weight: 400;
  vertical-align: baseline;
}

.page-title-box {
  color: rgb(64, 155, 241);
}

.link_text:hover {
  color: rgb(11, 10, 10);
}

.sidebar_name {
  margin-top: 0px;
  margin-left: 10px;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1px;
}

@media (max-width: 768px) {
  .destopview {
    display: none;
  }

  .mobileview {
    display: block;
  }
  .header {
    width: 100%;
    height: 72px;
  }

  .header .page-title-box h3 {
    color: #0d99ff;
    font-size: 20px;
    font-weight: normal;
    margin-left: 12px;
  }

  .contain {
    width: 100%;
    margin-top: 72px;
    background: #f8f9fa;
    transition: all 0.3s;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 0px;
  }

  .categorylist_page {
    background: #fff;
  }

  .inputdata_col_div {
    margin-left: 0%;
  }
}

@media (max-width: 768px) {
  .sidebar_close_button {
    display: block;
  }
}

@media (max-width: 480px) {
  .sidebar_close_button {
    display: block;
  }
}

@media (max-width: 320px) {
  .contain {
    width: 100%;
    margin-top: 72px;
    background: #f8f9fa;
    transition: all 0.3s;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 0px;
  }

  .sidebar_close_button {
    display: block;
  }

  .modal-backdrop.show {
    opacity: 0;
  }

  .combind {
    width: 228%;
  }
}
/* ________Editprofile Start__________   */
.Profile_edit_header {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: #16a34a;
}
.profile_edit_background {
  background: #ebffeb;
  padding-bottom: 30px;
}
.profileedit_start {
  margin-top: 30px;
}
.Edit_profile_formpage {
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-top: 25px;
}
.edit_profile_box {
  position: relative;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.editprofile_editicon {
  position: absolute;
  left: 140px;
  top: 40px;
}
.edit_profile_input {
  position: absolute;
  left: 0px;
  top: 20px;
  opacity: 0;
}
.edit_icon_profile {
  width: 50px;
}
.edit_profile_user {
  width: 150px;
  height: 150px;
  border-radius: 100%;
}
.edit_profile_form_page {
  padding-left: 30px;
  padding-right: 30px;
}
.edit_profile_lebal {
  padding-left: 5px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: #181818;
}
.edit_profile_input_area {
  border: 1px solid rgba(0, 0, 0, 0.25);
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.05));
  border-radius: 12px;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
}
.edit_profile_input_area::placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #232323;
}
.editprofile_savebutton {
  background: #16a34a;
  box-shadow: 0px 4px 10px rgba(233, 68, 75, 0.25);
  border-radius: 12px;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #ffffff;
}
.status_switch {
  font-size: 20px;
}

/* ____________EditProfile End___________ */
.cellAction {
  display: flex;
  align-items: center;
  gap: 15px;
}
.viewButton {
  padding: 2px 5px;
  border-radius: 5px;
  color: darkblue;
  border: 1px dotted rgba(0, 0, 139, 0.596);
  cursor: pointer;
}
.disableButton {
  padding: 2px 5px;
  border-radius: 5px;
  color: grey;
  border: 1px dotted rgba(0, 0, 139, 0.596);
  cursor: pointer;
}

.deleteButton {
  padding: 2px 5px;
  border-radius: 5px;
  color: crimson;
  border: 1px dotted rgba(220, 20, 60, 0.6);
  cursor: pointer;
}
.activeButton {
  padding: 2px 5px;
  border-radius: 5px;
  color: rgb(18, 238, 102);
  border: 1px dotted rgba(220, 20, 60, 0.6);
  cursor: pointer;
}
.inactiveButton {
  padding: 2px 5px;
  border-radius: 5px;
  color: rgb(26, 188, 241);
  border: 1px dotted rgba(220, 20, 60, 0.6);
  cursor: pointer;
}
.cellWithStatus {
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.active {
  background-color: rgba(0, 128, 0, 0.05);
  color: green;
}
.pending {
  background-color: rgba(255, 217, 0, 0.05);
  color: goldenrod;
}
.inactive {
  background-color: rgba(255, 0, 0, 0.05);
  color: crimson;
}
.css-f3jnds-MuiDataGrid-columnHeaders {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: blanchedalmond;
}
