.preLoaderContainer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
  }
  .loading-bar {
    position: fixed;
    width: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
}
  .preLoaderContainer .loading-bar .progress-bar {
    display: flex;
    height: 3px;
    width: 100%;
    background: #0d99ff;
    -webkit-animation: progress-animation 3s ease-in-out;
    animation: progress-animation 3s ease-in-out;
  }
  
  @-webkit-keyframes progress-animation {
    0% {
      width: 0%;
    }
  
    20% {
      width: 10%;
    }
  
    40% {
      width: 30%;
    }
  
    50% {
      width: 60%;
    }
  
    100% {
      width: 90%;
    }
  }
  
  @keyframes progress-animation {
    0% {
      width: 0%;
    }
  
    20% {
      width: 10%;
    }
  
    40% {
      width: 30%;
    }
  
    50% {
      width: 60%;
    }
  
    100% {
      width: 90%;
    }
  }