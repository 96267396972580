.card1{

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    /* margin-right: 20px; */
    max-height:  100px;
    width: 100%;
    -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px 2px rgb(0 0 0 / 20%);
    background: #fff;
    border-radius: 5px;
    

}
.card_left{
    /* position: relative; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.card_left .card-span{
    position: relative;
    background: #ff9b4433;
    display:flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
}
.cubes_icon{
    
    font-size: 35px;
    color:  #ff9b44;
    padding-bottom: 5px;
    

}
.card_right{
    
    /* width: 90px; */
    text-align: right;
    
    
}
.card_right strong{
    box-sizing: border-box;
    font-size: 30px;
    font-weight: bold;
    
  
}
.card_right p{
    box-sizing: border-box;
    font-size: 18px;
    margin-top: 20px;
}
